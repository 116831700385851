import React from "react";
import { graphql, Link } from "gatsby";
import sortBy from "lodash/sortBy";
import {
  PageQueryProps,
  RemarkFile,
  DataConnection,
  RemarkDocument,
} from "~/model";
import Seo from "~/components/Seo";
import FaqsAccordion from "~/components/FaqsAccordion";

type FaqCategoryPageProps = PageQueryProps<{
  readonly category: RemarkDocument<{
    readonly title: string;
  }>;
  readonly categoryFiles: DataConnection<
    RemarkFile<{
      readonly title: string;
      readonly slug: string;
      readonly order: number;
    }>
  >;
  readonly faqFiles: DataConnection<
    RemarkDocument<{
      readonly question: string;
      readonly order: number;
    }>
  >;
}>;

function FaqCategoryPage({
  data: { categoryFiles, faqFiles },
  // TODO: Show active state on button
  // pageContext: { categorySlug }
}: FaqCategoryPageProps) {
  const sortedCategories = sortBy(
    categoryFiles.edges.map((e) => e.node.childMarkdownRemark.frontmatter),
    "order",
  );
  const sortedFaqs = sortBy(
    faqFiles.edges.map(({ node }) => ({
      id: node.id,
      html: node.html,
      ...node.frontmatter,
    })),
    "order",
  );
  return (
    <>
      <section
        id="faq-header-16"
        className="Index-page"
        data-collection-id="6211012b1dac123e9d55c714"
        data-edit-main-image="Background"
      >
        <div
          className="Index-page-scroll-indicator"
          data-controller="ScrollIndicator"
          data-controllers-bound="ScrollIndicator"
        >
          <div className="Index-page-scroll-indicator-text">Scroll</div>
          <svg
            className="Index-page-scroll-indicator-arrow Icon Icon--caretLarge--down"
            viewBox="0 0 48 23"
          >
            <use xlinkHref="/assets/ui-icons.svg#caret-down-large-icon"></use>
          </svg>
          <div className="Index-page-scroll-indicator-line"></div>
        </div>
        <div className="Index-page-content ">
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-6211012b1dac123e9d55c714"
          >
            <div className="row sqs-row">
              <div className="col sqs-col-12">
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-d6df32ec5674e17e4df0"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      ProblemScape Support Center
                    </h2>
                  </div>
                </div>
                <div
                  className="sqs-block spacer-block sqs-block-spacer"
                  data-aspect-ratio="0.11961722488038277"
                  data-block-type="21"
                  id="block-f6f3b2224df71ddaa36e"
                >
                  <div
                    className="sqs-block-content sqs-intrinsic"
                    id="yui_3_17_2_1_1671159857644_7194"
                    style={{ paddingBottom: "0.119617%" }}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className="row sqs-row">
                  <div className="col sqs-col-3 span-3">
                    <div
                      className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                      data-block-type="21"
                      id="block-2315481594e3048126f9"
                    >
                      <div className="sqs-block-content">&nbsp;</div>
                    </div>
                  </div>
                  <div className="col sqs-col-6 span-6">
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-cb81d5e417af4302cbd4"
                    >
                      <div className="sqs-block-content">
                        <h3>Steps to get started</h3>
                        <ol>
                          <li>
                            Sign up for an administrator account with RoundEd
                            Learning.
                          </li>
                          <li>
                            Add a student account and create a login and
                            password.
                          </li>
                          <li>Choose a payment option and complete payment.</li>
                          <li>
                            You can now download the game in any device that
                            your child uses, from the corresponding App Store,
                            by searching for ProblemScape.
                          </li>
                          <li>
                            That&apos;s it! Your child can log in and start
                            playing.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col sqs-col-3 span-3">
                    <div
                      className="sqs-block button-block sqs-block-button"
                      data-block-type="53"
                      id="block-24a99cf4757b91f68b89"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671159857644_7147"
                      >
                        <div
                          className="sqs-block-button-container sqs-block-button-container--center"
                          data-animation-role="button"
                          data-alignment="center"
                          data-button-size="small"
                          data-button-type="tertiary"
                          id="yui_3_17_2_1_1671159857644_7146"
                        >
                          <Link
                            to="/stepbystep/"
                            className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element"
                            data-initialized="true"
                          >
                            Setup Instructions and Chapter Guides
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  className="sqs-block horizontalrule-block sqs-block-horizontalrule"
                  data-block-type="47"
                  id="block-6d1c3b733f341c8ad13b"
                >
                  <div className="sqs-block-content">
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="Index-page"
        data-collection-id="61f1a9f8b5ac953c2d7cfea4"
        data-edit-main-image="Background"
      >
        <div className="Index-page-content ">
          <div className="sqs-layout sqs-grid-12 columns-12" data-type="page">
            <div className="row sqs-row">
              <div className="col sqs-col-12">
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-95eb661a43954b842eb1"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      Frequently Asked Questions
                    </h2>
                  </div>
                </div>
                <div
                  className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                  data-aspect-ratio="0.23923444976076555"
                  data-block-type="21"
                  id="block-1f58da4dfa4726edb350"
                >
                  <div
                    className="sqs-block-content sqs-intrinsic"
                    id="yui_3_17_2_1_1671159857644_4673"
                    style={{ paddingBottom: "0.239234%" }}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className="row sqs-row">
                  <div className="col sqs-col-3 span-3">
                    {sortedCategories.map((category) => (
                      <div
                        key={category.slug}
                        className="sqs-block button-block sqs-block-button"
                      >
                        <div className="sqs-block-content">
                          <div
                            className="sqs-block-button-container sqs-block-button-container--left"
                            data-animation-role="button"
                            data-alignment="left"
                            data-button-size="small"
                            data-button-type="tertiary"
                            id="yui_3_17_2_1_1671159857644_4629"
                          >
                            <Link
                              to={`/support-center/${category.slug}/`}
                              className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element"
                              data-initialized="true"
                            >
                              {category.title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col sqs-col-9">
                    <FaqsAccordion faqs={sortedFaqs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Index-page">
        <div
          className="sqs-layout sqs-grid-12 columns-12"
          style={{ marginBottom: 50 }}
        >
          <div className="row sqs-row">
            <div className="col sqs-col-3 span-3">&nbsp;</div>
            <div className="col sqs-col-6 span-6">
              <hr />
              <p style={{ textAlign: "center" }}>
                If you still need help or have any questions after checking out
                the guides and FAQ, please email{" "}
                <a
                  href="mailto:support@roundedlearning.com"
                  style={{ color: "#e37263" }}
                >
                  support@roundedlearning.com
                </a>
                .
              </p>
            </div>
            <div className="col sqs-col-3 span-3">&nbsp;</div>
          </div>
        </div>
      </section>
    </>
  );
}

export const pageQuery = graphql`
  query FaqCategoryPageQuery($categoryId: String!, $categorySlug: String!) {
    category: markdownRemark(id: { eq: $categoryId }) {
      frontmatter {
        title
      }
    }
    categoryFiles: allFile(
      filter: { sourceInstanceName: { eq: "faq-categories" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              slug
              title
              order
            }
          }
        }
      }
    }
    faqFiles: allMarkdownRemark(
      filter: { frontmatter: { categorySlug: { eq: $categorySlug } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            question
            order
          }
        }
      }
    }
  }
`;

export function Head({
  data: {
    category: { frontmatter },
  },
}: FaqCategoryPageProps) {
  return <Seo title={`FAQs - ${frontmatter.title}`} />;
}

export default FaqCategoryPage;
